import React, {useEffect} from 'react';
import Layout from '../components/Layout';
import BackgroundImage from 'gatsby-background-image';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import {convertToBgImage} from 'gbimage-bridge';
import {StaticImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';
import Button from '../components/Button';
import {TitleBordered} from '../components/utils';

// svgs
import Logo from '../images/trustle-logo-white.svg';
import peopleJit from '../images/product/people-jit-v2.svg';

// Videos
import LicenseCostV2 from '../animations/mp4/license-cost-savings-v2.mp4';
import Compliance from '../animations/mp4/compliance.mp4';
import Schedule from '../animations/mp4/scheduled-access.mp4';

const sectionTextClass = `mb-6 text-lg text-left xl:leading-loose text-black opacity-60`;

// to do: merge this component into SectionIntro component
const SectionIntroTwoBG = ({color = 'blue', hero = false, title, description, isPageHeader = false}) => {
  const titleClassName = `text-left leading-11 md:leading-[60px] font-bold text-3xl md:text-5xl text-transparent bg-trustle-gradient bg-clip-text mb-4 md:mb-12 max-w-[725px]`;
  return (
    <section className="relative pt-16 pb-8">
      <div className="absolute right-0 hidden mb-3 xl:flex max-w-[650px] 3xl:max-w-none 4xl:hidden">
        <StaticImage src="../images/homepage/homepage-notebook-01.png" alt="trustle app" className="mb-8" />
      </div>

      <div className="px-5 pt-0 mx-auto mb-20 text-center text-white max-w-[1360px]">
        <div className="flex flex-col items-center   md:flex-row md:h-[450px] 2xl:h-[600px]">
          <div className="">
            {hero && <p className={`text-center text-xl text-${color}`}>{hero}</p>}
            {isPageHeader ? <h1 className={titleClassName}>{title}</h1> : <div className={titleClassName}>{title}</div>}
            <p className={`md:text-2xl text-xl text-left max-w-4xl mb-8 md:mb-16 text-${color}`}>{description}</p>
            <div className="py-4 mb-12 lg:mb-0 md:w-[180px] text-left">
              <Link to="/demo">
                <Button label="Schedule Demo" />
              </Link>
            </div>
          </div>

          <div className="mb-3 xl:hidden 4xl:block">
            <StaticImage
              src="../images/homepage/homepage-notebook-01.png"
              alt="People working happily"
              quality={100}
              className="mb-8"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const IndexPage = ({location}) => {
  useEffect(() => {
    location?.state?.scrollY && window.scroll(0, location?.state?.scrollY);
  }, [location?.state?.scrollY]);

  const {placeholderImage, background, backgroundRussel} = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
          }
        }
        background: file(relativePath: {eq: "bg/rebrand/bg-main-middle-bottom.jpg"}) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
          }
        }
        backgroundRussel: file(relativePath: {eq: "bg/bg-section-header.jpg"}) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
          }
        }
      }
    `
  );

  const bgImage = convertToBgImage(getImage(placeholderImage));
  const bgImageMiddle = convertToBgImage(getImage(background));
  const bgImageRussel = convertToBgImage(getImage(backgroundRussel));

  const dataSalDefault = {
    'data-sal-duration': '700',
    'data-sal-delay': '300',
    'data-sal-easing': 'ease',
  };

  return (
    <Layout>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
      >
        <div className="relative">
          <SectionIntroTwoBG
            logo={Logo}
            title={`Automate just-in-time (JIT) access and surface identity risks across all cloud environments.`}
            description="Setup, deploy, and see results in under 30 minutes. "
            isPageHeader
          />
        </div>

        <section className="relative pt-16 ">
          <div className="mb-8 lg:mb-24">
            <TitleBordered title="Why Trustle?" />
          </div>

          <div className="px-5 xl:px-0 pt-0 mx-auto text-center text-white max-w-[1360px]">
            <div className="flex flex-col justify-between ">
              <div className="flex flex-wrap items-center justify-between mb-8 text-left md:flex-row-reverse text-blue lg:mb-32">
                <div className="w-full max-w-xl md:p-4 md:w-1/2" data-sal="slide-left" {...dataSalDefault}>
                  <div className="mb-4 text-3xl font-bold text-left md:text-2xl">
                    Enable Multi Cloud On-demand & Scheduled Access
                  </div>
                  <p className={sectionTextClass}>
                    Keep your users zero-standing until they need least privileged access for 9 to 5, On-Call, and
                    customize usage scenarios.<br></br>
                    <br></br>
                    Detect identity risks - shadow users, overprivileged accounts, unnecessary standing access - and
                    mitigate potential issues.
                  </p>
                  <div className="py-4 mb-12 lg:mb-0 md:w-[180px] text-left">
                    <Link to="/product/jit">
                      <Button label="Learn more" />
                    </Link>
                  </div>
                </div>
                <div className="w-full md:p-4 md:w-1/2" data-sal="slide-right" {...dataSalDefault}>
                  <video autoPlay loop muted playsInline className="mb-8 " src={Schedule} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImageMiddle}
        preserveStackingContext
      >
        <section className="relative ">
          <div className="px-5 xl:px-0 pt-0 mx-auto mb-20 text-center text-white max-w-[1360px]">
            <div className="flex flex-col justify-between ">
              <div className="flex flex-wrap items-center justify-between mb-8 text-left md:flex-row text-blue lg:mb-32">
                <div className="w-full max-w-xl md:p-4 md:w-1/2" data-sal="slide-right" {...dataSalDefault}>
                  <div className="mb-4 text-3xl font-bold text-left md:text-2xl">Save on License Costs</div>
                  <p className={sectionTextClass}>
                    Identify who has access to what in your environment, with context into access policies, approvals,
                    and unused licenses. <br></br>
                    <br></br>Recuperate unused license seats costs by ensuring existing seats are assigned to active-use
                    users.
                  </p>
                </div>
                <div className="w-full md:p-4 md:w-1/2" data-sal="slide-left" {...dataSalDefault}>
                  <video autoPlay loop muted playsInline className="mb-8 " src={LicenseCostV2} />
                </div>
              </div>

              <div className="flex flex-wrap items-center justify-between mb-8 text-left md:flex-row-reverse text-blue">
                <div className="w-full max-w-xl md:p-4 md:w-1/2" data-sal="slide-left" {...dataSalDefault}>
                  <div className="mb-4 text-3xl font-bold text-left md:text-2xl">Simplify Compliance</div>
                  <p className={sectionTextClass}>
                    Generate access review reports with full audit trails to assist regulatory reviews and maintain
                    compliance.<br></br>
                    <br></br> Understand which users have access to what, who approved it, why, and for how long – at
                    audit time, or anytime.
                  </p>
                  <div className="py-4 mb-12 lg:mb-0 md:w-[180px] text-left">
                    <Link to="/product/complianceSimplified">
                      <Button label="Learn more" />
                    </Link>
                  </div>
                </div>
                <div className="w-full md:p-4 md:w-1/2" data-sal="slide-right" {...dataSalDefault}>
                  <video autoPlay loop muted playsInline className="mb-8 " src={Compliance} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      <section data-sal="fade" data-sal-duration="700" data-sal-delay="300" data-sal-easing="ease">
        <div className="px-5 pt-8 pb-32 mx-auto text-center text-white lg:mb-0 max-w-8xl ">
          <div className={`text-3xl font-bold text-blue mb-4 max-w-[1130px] mx-auto`}>
            "I was able to install, connect my infrastructure, onboard users, and begin <br></br>analyzing my
            organization's identity posture with Trustle in less than 30 minutes."
          </div>
          <div className="text-center text-black opacity-60">Charles Hausmann. - Peloton Group LLC</div>
        </div>
      </section>

      {/* Banner JIT */}
      <div className="relative ">
        <section className="relative mb-20 xl:mb-56 pb-28">
          <BackgroundImage Tag="section" {...bgImageRussel} preserveStackingContext className="relative">
            <div className="relative flex flex-col items-center justify-center lg:justify-start w-full px-5 pt-12 pb-8 mb-8 text-white md:flex-row lg:max-w-[1360px] lg:mx-auto filter drop-shadow-2xl">
              <div className="relative flex flex-col-reverse items-center justify-center mt-4 md:mt-0">
                <div>
                  <h3 className="pb-4 text-3xl font-bold md:text-2xl">
                    Don’t wait for the JIT to hit the fan.<br></br> Secure your Cloud Environment today with Trustle!
                  </h3>
                  <div className=" pb-7 text-md md:text-lg">
                    Integrated Seamless Resource Access, Powerful Recommendation engine, and Streamlined Compliance
                    within One App.
                  </div>
                  <div className="md:w-[180px] text-left">
                    <Link to="/demo">
                      <Button label="Schedule Demo" variant="secondary" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="absolute flex flex-col justify-between h-full top-[400px] md:top-36 md:left-52">
                <img src={peopleJit} alt="People JIT" className="" />
              </div>
            </div>
          </BackgroundImage>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
